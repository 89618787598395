export const radioOptions = [{
  label: 'Web',
  value: 'Web',
}, {
  label: 'Mobile',
  value: 'Mobile',
}, {
  label: 'Email',
  value: 'Email',
},
{
  label: 'Text',
  value: 'Text',
}];

export const NOTIFICATION_DEVICE_OPTIONS = [
  {
    label: __('Inapp'),
    value: 'Inapp',
  },
  {
    label: __('Web'),
    value: 'Web',
  },
  {
    label: __('Email'),
    value: 'Email',
  },
];

export const NOTIFICATION_DAYS = [
  { label: 'Mon', value: '1' },
  { label: 'Tue', value: '2' },
  { label: 'Wed', value: '3' },
  { label: 'Thu', value: '4' },
  { label: 'Fri', value: '5' },
  { label: 'Sat', value: '6' },
  { label: 'Sun', value: '0' },
];
export const TIME_FORMAT = 'HH:mm';
export const INVALID_DATE = 'Invalid date';
export const TIME_FIELDS = [
  { value: '300', label: '5', suffix: 'minutes' },
  { value: '1800', label: '30', suffix: 'minutes' },
  { value: '3600', label: '1', suffix: 'hour' },
  { value: '14400', label: '4', suffix: 'hours' },
  { value: '28800', label: '8', suffix: 'hours' },
  { value: 'tomorrow', label: '(9 am)', prefix: 'Tomorrow' },
];

export const TAB_GROUP_OPTIONS = [{
  label: 'Task Management',
  key: 'Task Management',
}];

export const APP_BASED_SUMMARY = {
  'Task Management': [{
    title: __('Daily summaries'),
    content: __('New tasks assigned to you and upcoming due dates'),
    type: 'isDailySummary',
  },
  {
    title: __('Receive task updates'),
    content: __(' Notify task creators or assignee or collaborator on any field updated in a task'),
    type: 'notifyTaskUpdate',
    resetActions: (props) => {
      const {action} = props;
      return {
        notifyTaskUpdateAssignee: action, 
        notifyTaskUpdateCollabrator: action, 
        notifyTaskUpdateCreatedBy: action
      }
    },
    childrens: [
      {
        title: __('When I am the assignee'),
        content: __('Notify assignees of the task on any field updated in a task'),
        type: 'notifyTaskUpdateAssignee',
        resetActions: (props) => {
          const {notifyTaskUpdateAssignee, notifyTaskUpdateCollabrator, notifyTaskUpdateCreatedBy} = props;
          return {notifyTaskUpdate: !!(notifyTaskUpdateAssignee && notifyTaskUpdateCollabrator && notifyTaskUpdateCreatedBy)}
        }
      },
      {
        title: __('When I am the Collaborator'),
        content: __('Notify task colaborators on any field updated in a task'),
        type: 'notifyTaskUpdateCollabrator',
        resetActions: (props) => {
          const {notifyTaskUpdateAssignee, notifyTaskUpdateCollabrator, notifyTaskUpdateCreatedBy} = props;
          return {notifyTaskUpdate: (notifyTaskUpdateAssignee && notifyTaskUpdateCollabrator && notifyTaskUpdateCreatedBy)}
        }
      },
      {
        title: __('When I am the creator'),
        content: __('Notify task creators on any field updated in a task'),
        type: 'notifyTaskUpdateCreatedBy',
        resetActions: (props) => {
          const {notifyTaskUpdateAssignee, notifyTaskUpdateCollabrator, notifyTaskUpdateCreatedBy} = props;
          return {notifyTaskUpdate: (notifyTaskUpdateAssignee && notifyTaskUpdateCollabrator && notifyTaskUpdateCreatedBy)}
        }
      },
    ]
  },
  {
    title: __('Task comments'),
    content: __('Notify users for mentions in a task'),
    type: 'notifyMention',
  }
  // {
  //   title: 'Program alert',
  //   content: 'Notify me when each program changes',
  //   type: 'ptSummary',
  // }
],
};
