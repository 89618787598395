exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".PageHeader_breadcrumb__-Wg4Z{font-family:Proxima-Nova-Semibold;letter-spacing:.03rem;color:#161616}[dir] .PageHeader_breadcrumb__-Wg4Z{padding:2rem}.PageHeader_breadcrumb__-Wg4Z .ant-breadcrumb-link{font-size:2.4rem}.PageHeader_breadcrumb__-Wg4Z .ant-breadcrumb-separator{font-size:2.4rem}.PageHeader_versionHeader__1FBFs{display:flex;justify-content:space-between}.PageHeader_versionHeader__1FBFs .PageHeader_flexHeader__XKCHz{display:flex;align-items:center}[dir=ltr] .PageHeader_versionHeader__1FBFs .PageHeader_flexHeader__XKCHz{padding-right:1.8rem}[dir=rtl] .PageHeader_versionHeader__1FBFs .PageHeader_flexHeader__XKCHz{padding-left:1.8rem}.PageHeader_versionHeader__1FBFs .PageHeader_heading__2IWc3{font-family:Proxima-Nova-Semibold;letter-spacing:.03rem;color:#161616;font-size:2.4rem}[dir] .PageHeader_versionHeader__1FBFs .PageHeader_heading__2IWc3{margin:2rem}[dir] .PageHeader_versionHeader__1FBFs .PageHeader_add__btn__fVYqa{margin:2rem}", ""]);

// Exports
exports.locals = {
	"breadcrumb": "PageHeader_breadcrumb__-Wg4Z",
	"versionHeader": "PageHeader_versionHeader__1FBFs",
	"flexHeader": "PageHeader_flexHeader__XKCHz",
	"heading": "PageHeader_heading__2IWc3",
	"add__btn": "PageHeader_add__btn__fVYqa"
};