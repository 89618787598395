import React from 'react';
import PropTypes from 'prop-types';
import { withOktaAuth } from '@okta/okta-react';
import Checkbox from 'tcomponents/atoms/checkbox';
import Input from 'tcomponents/atoms/Input';
import Button from 'tcomponents/atoms/Button';
import { emailRegex, sanitizeUrl } from '@tekion/tap-components/utils/helper';
import Loader from '@tekion/tap-components/atoms/Loader';
import logo from '../../asset/login.png';
import styles from './signIn.module.scss';
import TokenManager from '@tekion/tap-components/utils/TokenManager';
import CookieHelper from '@tekion/tap-components/utils/CookieHelper';
import { clearMigratedTokens, getLandingPageUrl, redirectToSAMLLogin } from '../../utils/helper';

class SignIn extends React.Component {
  constructor() {
    super();
    const rememberMe = TokenManager.getItem('rememberMe');
    const email = rememberMe ? TokenManager.getItem('email') : '';
    this.state = {
      isLoading: true,
      email,
      rememberMe,
    };
  }

  componentDidMount() {
    // to redirect to home page when user successfully login
    clearMigratedTokens();
    const accessToken = TokenManager.getItem(TokenManager.accessTokenKey);
    let routeUrl;
    const { location } = this.props;
    if (location && location.search && location.search.indexOf('url=') !== -1) {
      routeUrl = location.search.split('url=');
      [, routeUrl] = routeUrl;
    }
    if (accessToken && CookieHelper.getCookie('isAuthenticated') === 'true') {
      const redirectUrl = sanitizeUrl(this.removeLogout(routeUrl)) || getLandingPageUrl();
      const loginRelatedUrls = ['/app/callback', '/app/singlesignon', '/login', '/logout'];
      if (!loginRelatedUrls.includes(redirectUrl)) {
        window.location.href = redirectUrl;
      } else {
        window.location.href = '/app/home';
      }
    } else {
      this.setState({ isLoading: false });
    }
  }

  handleInputOnChange = name => (event) => {
    const { value } = event.target;
    this.setState({ [name]: value, showErrorMsg: false });
  };

  onRememberMe = (event) => {
    const { checked } = event.target;
    this.setState({ rememberMe: checked });
  };

  onKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.takeToLogin();
    }
  };

  removeLogout = url => ((url || '').replace('&logout=true', '').replace('?logout=true', ''));

  takeToLogin = () => {
    const { email, rememberMe } = this.state;
    if (emailRegex.test(email)) {
      this.setState({ showErrorMsg: false, showLoader: true });

      let redirectUrl = getLandingPageUrl();
      if (window.location.search && window.location.search.includes('url=')) {
        const [, routeUrl] = window.location.search.split('url=');
        redirectUrl = sanitizeUrl(this.removeLogout(routeUrl)) || redirectUrl;
      }
      CookieHelper.setCookie('tap_login_redirect_uri', sanitizeUrl(redirectUrl));
      TokenManager.setItem('rememberMe', rememberMe);

      const { isDuoAuth } = this.props;
      if (isDuoAuth) {
        redirectToSAMLLogin();
        return;
      } else {
        const { oktaAuth } = this.props;
        oktaAuth.signInWithRedirect({ loginHint: email });
      }
    } else {
      this.setState({ showErrorMsg: true, showLoader: false });
    }
  };

  render() {
    const {
      isLoading, showErrorMsg, email, rememberMe, showLoader,
    } = this.state;
    return (
      <React.Fragment>
        {isLoading ? <Loader id="tap-login-page-loader" />
          : (
            <div className={styles.loginContainer}>
              {showLoader ? <Loader id="tap-login-page-loader" /> : null}
              <div className={styles.leftSection}>
                <img src={logo} alt={__('Tekion Automation Platform')} />
              </div>
              <div className={styles.rightSection}>
                <div className={styles.formContainer}>
                  <h1>{__('Sign In')}</h1>
                  <Input
                    autoComplete="on"
                    className={styles.signInInput}
                    id="loginEmail"
                    name="email"
                    onChange={this.handleInputOnChange('email')}
                    onFocus={this.handleOnFocus}
                    onKeyDown={e => this.onKeyDown(e)}
                    placeholder={__('Enter email')}
                    value={email}
                    type="email"
                    autoFocus
                  />
                  {showErrorMsg && (
                    <div className={styles.errormessage}>
                      {__('Enter valid email address')}
                    </div>
                  )}
                  <div className={styles.rememberMeSection}>
                    <Checkbox
                      className={styles.rememberMe}
                      checked={rememberMe}
                      onChange={e => this.onRememberMe(e)}
                    >
                      {__('Remember me')}
                    </Checkbox>
                  </div>
                  <div className={styles.submitBtnContainer}>
                    <Button
                      id="signInSubmitBtn"
                      type="button"
                      onClick={() => this.takeToLogin()}
                      className={styles.submitBtn}
                    >
                      {__('Continue')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </React.Fragment>
    );
  }
}
SignIn.propTypes = {
  oktaAuth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  isDuoAuth: PropTypes.bool,
};

SignIn.defaultProps = {
  isDuoAuth: false,
};

export default withOktaAuth(SignIn);
