exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".MobileConfigurations_container__-rmHS{position:absolute;width:calc(100% - 6.4rem)}[dir] .MobileConfigurations_container__-rmHS{border-radius:1.5rem}[dir=ltr] .MobileConfigurations_container__-rmHS{margin:6.2rem 0 0 6.4rem}[dir=rtl] .MobileConfigurations_container__-rmHS{margin:6.2rem 6.4rem 0 0}.MobileConfigurations_container__-rmHS .MobileConfigurations_list__1D-Sn{display:flex;flex-wrap:wrap;overflow:scroll;height:calc(100vh - 25rem)}.MobileConfigurations_container__-rmHS .MobileConfigurations_list__1D-Sn .MobileConfigurations_configItem__1cYYl{width:30rem}[dir] .MobileConfigurations_container__-rmHS .MobileConfigurations_list__1D-Sn .MobileConfigurations_configItem__1cYYl{border:1px solid #e8e8e8;margin:2rem;box-shadow:0 .4rem .8rem 0 rgba(0,0,0,.2)}.MobileConfigurations_container__-rmHS .MobileConfigurations_list__1D-Sn .MobileConfigurations_platform__2LHee{font-weight:bold;font-size:2rem;text-transform:uppercase}[dir] .MobileConfigurations_container__-rmHS .MobileConfigurations_list__1D-Sn :hover{cursor:pointer}", ""]);

// Exports
exports.locals = {
	"container": "MobileConfigurations_container__-rmHS",
	"list": "MobileConfigurations_list__1D-Sn",
	"configItem": "MobileConfigurations_configItem__1cYYl",
	"platform": "MobileConfigurations_platform__2LHee"
};