import CookieHelper from '@tekion/tap-components/utils/CookieHelper';
import { sanitizeUrl } from '@tekion/tap-components/utils/helper';
import TokenManager from '@tekion/tap-components/utils/TokenManager';
import { useEffect } from 'react';
import { getLandingPageUrl } from '../../utils/helper';

const { BASE_URL } = process.env;

const DuoAuthCallback = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.hash?.substring(1));
    const accessToken = params.get('access_token');
    const redirectUrl = params.get('redirect') || getLandingPageUrl();
    
    CookieHelper.setCookie('tap_login_redirect_uri', sanitizeUrl(redirectUrl));

    const options = {
      authenticateApiUrl: `${BASE_URL}/tapapi/v2/usermanagement/authenticate-okta-user`,
      redirectUrl: redirectUrl || getLandingPageUrl(),
    };
    TokenManager.persistUserAuth({
      accessToken: {
        accessToken
      }
    }, options);
  }, []);

  return null;
}

export default DuoAuthCallback;
