/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Menu, Icon,
} from 'antd';
import { connect } from 'react-redux';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import Button from 'tcomponents/atoms/Button';
import moment from 'moment';
import Dropdown from 'tcomponents/molecules/DropDown';
import Checkbox from 'tcomponents/atoms/checkbox';
import TimePicker from 'tcomponents/atoms/TimePicker';
import CheckboxGroup from 'tcomponents/atoms/checkboxGroup';
import FontIcon from 'tcomponents/atoms/FontIcon';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tap-components/constants/Constants';
import { isEmpty } from '@tekion/tap-components/utils/helper';
import { getValueFromEnv } from '@tekion/tap-components/utils/commonHelper';
import { omitFieldsFromObject } from '@tekion/tap-components/utils/helper.object';
import _get from 'lodash/get';
import style from './NotificationSettings.module.scss';
import {
  NOTIFICATION_DEVICE_OPTIONS, INVALID_DATE, NOTIFICATION_DAYS,
  TIME_FIELDS, TIME_FORMAT, TAB_GROUP_OPTIONS, APP_BASED_SUMMARY,
} from './Constants';
import TabGroup from '../../../molecules/TabGroup/TabGroup';
import { getUserPreferences, updatePreferences, updateAppBasedSummary } from '../../../action/NotificationSettings.action';

const PARTNERS_SITE = getValueFromEnv('PARTNERS_SITE');

const isPartnerSite = PARTNERS_SITE === 'true';

class NotificationSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loaded: false,
      days: [],
      scheduleFrom: '',
      scheduleTill: '',
      schedule: false,
      isDailySummary: false,
      notifyTaskUpdate: false,
      notifyTaskUpdateAssignee: false,
      notifyTaskUpdateCollabrator: false,
      notifyTaskUpdateCreatedBy: false,
      notifyMention: false,
      showTime: '',
      weekly: false,
      onlyToday: false,
      ptSummary: false,
      defaultView: [],
      savePayload: EMPTY_OBJECT,
      selectedTab: 'Task Management',
      isApiFailed: false,
    };
  }

  componentDidMount = () => {
    const { fetchUserPreference, apiBaseUrl } = this.props;
    fetchUserPreference(apiBaseUrl);
  }

  static getDerivedStateFromProps(props, state) {
    const { preference } = props;
    const { userPreference } = preference || {};
    const {
      isDailySummary = false,notifyMention = false,notifyTaskUpdateCreatedBy = false, notifyTaskUpdate = false, notifyTaskUpdateAssignee = false, notifyTaskUpdateCollabrator = false ,isPtSummary: ptSummary = false, isPaused: paused, fromTime: from, toTime: till,
      toToday: toTodayTime, isDaily: daily, isWeekly: weekly, isToday: onlyToday, defaultView, weekDay: pauseWeekDays, isCollapsed,
    } = userPreference || {};
    if ((!_isEmpty(userPreference) && !state.loaded) || state.isApiFailed) {
      return {
        loaded: true,
        days: Array.isArray(pauseWeekDays) ? [...pauseWeekDays] : EMPTY_ARRAY,
        scheduleFrom: moment(from, 'HH:mm:ss').local().format('HH:mm'),
        scheduleTill: moment(till, 'HH:mm:ss').local().format('HH:mm'),
        schedule: daily,
        isDailySummary,
        notifyMention,
        notifyTaskUpdateCreatedBy,
        notifyTaskUpdate,
        notifyTaskUpdateAssignee,
        notifyTaskUpdateCollabrator,
        ptSummary,
        isPaused: paused,
        weekly,
        onlyToday,
        showTime: moment.unix(toTodayTime).format('HH:mm'),
        expandAll: !isCollapsed ? 'expandAll' : 'collapseAll',
        defaultView,
        isApiFailed: false,
      };
    }
    if (_isEmpty(userPreference)) {
      return { loaded: false };
    }
    return null;
  }

  isApiFailed = () => {
    this.setState({ isApiFailed: true });
  }

  renderActions = () => (
    <Menu onClick={e => this.handleMenuClick(e)}>
      {TIME_FIELDS.map((dropOption) => {
        const {
          label, suffix = '', prefix = '', value,
        } = dropOption;
        const displayLabel = `${__(prefix)} ${__(label)} ${__(suffix)}`.trim();
        return (
          <Menu.Item key={value}>
            {displayLabel}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  setTime = () => {
    const date = moment().add('days', 1);
    date.set({ hour: '09', minute: '00', seconds: '00' });
    const duration = moment.duration(date.diff(moment()));
    const seconds = duration.asSeconds();
    return { seconds, date };
  }

  isPausedPayloadCheck = (payload) => {
    let resultPayload = { ...payload };
    const {
      onlyToday, weekly, schedule,
    } = this.state;
    const { preference: { userPreference: { isToday } } } = this.props;
    const { preference: { userPreference: { isWeekly } } } = this.props;
    const { preference: { userPreference: { isDaily } } } = this.props;

    if (isToday !== onlyToday || isWeekly !== weekly || isDaily !== schedule) {
      if (onlyToday || weekly || schedule) {
        resultPayload = { ...resultPayload, isPaused: true };
      } else {
        resultPayload = { ...resultPayload, isPaused: false };
      }
    }
    return resultPayload;
  }

  handleSavePayload = (payload, isChanged) => {
    const { savePayload } = this.state;
    const resultPayload = this.isPausedPayloadCheck(payload);
    if (isChanged) {
      this.setState({ savePayload: { ...savePayload, ...resultPayload } });
    } else {
      const keysToBeRemoved = Object.keys(resultPayload);
      const currentPayload = omitFieldsFromObject(keysToBeRemoved, savePayload);
      this.setState({ savePayload: currentPayload });
    }
  }

  handleMenuClick = (e) => {
    const { preference: { userPreference: { toToday: toTodayTime } } } = this.props;
    const currentTime = moment().unix();
    const tomorrow = this.setTime();
    const pauseTime = e.key === 'tomorrow' ? tomorrow.seconds : parseInt(e.key, 10);
    const toToday = e.key === 'tomorrow' ? tomorrow.date : moment().add(pauseTime, 'seconds');
    this.setState({
      onlyToday: true, showTime: toToday.format('HH:mm'),
    }, () => {
      const prevFromTime = moment.unix(toTodayTime).format('HH:mm');
      const payload = {
        isToday: true, fromToday: currentTime, toToday: currentTime + Math.round(pauseTime),
      };
      if (prevFromTime !== toToday.format('HH:mm')) {
        this.handleSave(payload);
      }
    });
  };

  timeFromOnChange = (time, timeString) => {
    this.setState({
      scheduleFrom: timeString,
    });
  };

  timeTillOnChange = (time, timeString) => {
    this.setState({
      scheduleTill: timeString,
    });
  };

  onTimeChange = (isTimePanelOpen) => {
    if (!isTimePanelOpen) {
      const { preference: { userPreference: { till, from } } } = this.props;
      const { scheduleFrom, schedule, scheduleTill } = this.state;

      const fromPrev = this.isDateValid(moment(from, 'HH:mm:ss').format('HH:mm'));
      const tillPrev = this.isDateValid(moment(till, 'HH:mm:ss').format('HH:mm'));
      const endTime = moment(scheduleTill, 'HH:mm:ss').utc();
      const startTime = moment(scheduleFrom, 'HH:mm:ss').utc();
      const fromTime = this.isDateValid(startTime.format('HH:mm:ss'));
      const toTime = this.isDateValid(endTime.format('HH:mm:ss'));
      const payload = {
        toTime,
        isDaily: schedule,
        fromTime,
      };
      if (fromPrev !== fromTime || tillPrev !== toTime) {
        this.handleSave(payload);
      }
    }
  }

  onChangeschedule = (e) => {
    const schedule = e.target.checked;
    const payload = { isDaily: schedule }
    this.setState({
      schedule,
    });
    // if(!e.target.checked) {
    this.handleSave(payload);
    // }
  };

  handlePTSummaryChange = (e) => {
    const { preference: { userPreference: { isPtSummary } } } = this.props;
    const ptSummary = e.target.checked;
    this.setState({
      ptSummary,
    }, () => {
      const payload = { isPtSummary: ptSummary };
      if (isPtSummary !== ptSummary) {
        const { updateSummary, apiBaseUrl } = this.props;
        updateSummary(apiBaseUrl, payload);
      }
    });
  }

  onChangeNotification = (e, value) => {
    let payload = {[value?.type] : e?.target.checked};
    if(value?.resetActions){
      payload = {...payload, ...(value?.resetActions({action: e?.target.checked, ...this.state, [value?.type]: e?.target.checked}))}
    }
    this.setState(payload, () => {
      const { updateSummary, apiBaseUrl } = this.props;
      updateSummary(apiBaseUrl, payload);
    });
  };

  handleDays = (e, val) => {
    const { preference: { userPreference: { weekDay } } } = this.props;
    const { days: daysInState } = this.state;
    const days = [...daysInState];
    const pauseWeekDays = Array.isArray(weekDay) ? [...weekDay] : EMPTY_ARRAY;

    if (days.includes(val)) {
      const daysVal = days.indexOf(val);
      days.splice(daysVal, 1);
      this.setState({ days }, () => {
        const payload = { weekDay: days, isWeekly: !isEmpty(days) };
        if (!_isEqual(days.sort(), pauseWeekDays.sort())) {
          this.handleSave(payload);
        }
      });
    } else {
      const currentDays = [...days, val];
      this.setState({ days: currentDays }, () => {
        const payload = { weekDay: currentDays, isWeekly: !isEmpty(currentDays) };
        if (!_isEqual(currentDays.sort(), pauseWeekDays.sort())) {
          this.handleSave(payload);
        }
      });
    }
  };

  resumeNotification = () => {
    const { preference: { userPreference: { isToday: paused } } } = this.props;
    this.setState({ onlyToday: false, showTime: EMPTY_STRING }, () => {
      const payload = { isToday: false };
      if (paused !== false) {
        this.handleSave(payload);
      }
    });
  };

  isDateValid = date => date === INVALID_DATE ? EMPTY_STRING : date;

  _renderPauseNotification = () => (
    <Dropdown overlay={this.renderActions} trigger={['click']}>
      <Button><FontIcon className={style.bellIcon}>icon-bell</FontIcon> {__('Pause notifications')}</Button>
    </Dropdown>
  );

  _renderResumeNotification = () => {
    const { loading } = this.state;
    return (
      <div>
        <Button onClick={this.resumeNotification}>
          <FontIcon className={style.bellIcon}>icon-bell</FontIcon> {__('Resume notifications')}
        </Button>
        <span>{loading && <Icon type="loading" />}</span>
      </div>
    );
  }

  handleSave = (payload) => {
    const { updatePreference, apiBaseUrl } = this.props;
    updatePreference(apiBaseUrl, payload, this.isApiFailed);
  };

  onTabChange = (val) => {
    this.setState({ selectedTab: val });
  }

  handlePayload = (payload, values) => ({ ...payload, ...values });

  onDeviceOptions = (value) => {
    const { preference: { userPreference: { defaultView: deviceOption } } } = this.props;
    if (!isEmpty(value)) {
      this.setState({ defaultView: [...value] });
      const payload = { defaultView: [...value] };
      if (!_isEqual(deviceOption, value)) this.handleSave(payload);
    }
  }

  render() {
    const {
      days, scheduleFrom, scheduleTill, schedule, selectedTab,
      showTime, defaultView, ptSummary, onlyToday,
    } = this.state;
    const taskUpdateTrueCondition = {
      notifyTaskUpdateAssignee: this.state?.notifyTaskUpdateAssignee, 
      notifyTaskUpdateCollabrator: this.state?.notifyTaskUpdateCollabrator, 
      notifyTaskUpdateCreatedBy: this.state?.notifyTaskUpdateCreatedBy
    }
    const taskUpdateChildsTrueCondition = {
      notifyTaskUpdate: this.state.notifyTaskUpdate      
    }
    return (
      <React.Fragment>
        <div className={style.settingsContainer}>
          <div className={style.defaultSection}>
            <h3>{__('General Settings')}</h3>
            <div className={style.notificationOptions}>
              <CheckboxGroup value={defaultView} options={NOTIFICATION_DEVICE_OPTIONS} onChange={this.onDeviceOptions}></CheckboxGroup>
            </div>
          </div>
          <div className={style.notificationSection}>
            <h4>{__('Do not disturb')}</h4>
            {onlyToday && <div>{__('Notifications paused until')} <span>{showTime}</span></div>}
            {!onlyToday ? this._renderPauseNotification() : this._renderResumeNotification()}
            <div className={style.scheduleSection}>
              <p>{__('Schedule')}</p>
              <div>
                <Checkbox checked={schedule} onChange={this.onChangeschedule} />
                <span className={style.scheduleTxt}>{__('Do not notify me from :')} </span>
                <span className={style.timeSelect}>
                  <TimePicker
                    onChange={this.timeFromOnChange}
                    value={moment(scheduleFrom, 'HH:mm').isValid() ? moment(scheduleFrom, TIME_FORMAT) : ''}
                    format={TIME_FORMAT}
                    disabled={!schedule}
                    id="from"
                    placeholder={__('From time')}
                  />
                </span>
                <span className={style.scheduleTxt}>{__('To:')}</span>
                <span className={style.timeSelect}>
                  <TimePicker
                    onOpenChange={this.onTimeChange}
                    onChange={this.timeTillOnChange}
                    value={moment(scheduleTill, 'HH:mm').isValid() ? moment(scheduleTill, TIME_FORMAT) : ''}
                    format={TIME_FORMAT}
                    disabled={!schedule}
                    id="to"
                    placeholder={__('To time')}
                  />
                </span>
              </div>
            </div>
            <div>{__('Do not disturb me on my days off')}</div>
            <div className={style.daysOff}>
              {NOTIFICATION_DAYS.map((daysOff, index) => (
                <div
                  className={days.indexOf(daysOff.value) > -1 ? style.days_active : style.days}
                  key={daysOff.label}
                  onClick={e => this.handleDays(e, daysOff.value)}
                  role="button"
                  tabIndex={index - 1}
                >
                  {__(daysOff.label)}
                </div>
              ))}
            </div>
            <div className={style.appBasedSection}>
              <h3>{__('App Based')}</h3>
              <div className={style.tabContainer}>
                <TabGroup
                  initialTab={selectedTab}
                  tabList={TAB_GROUP_OPTIONS}
                  tabBarStyle={{ backgroundColor: '#FFFFFF' }}
                  onChange={this.onTabChange}
                />
              </div>
            </div>
            {Object.entries(APP_BASED_SUMMARY).map((obj) => {
              if (obj[0] === selectedTab) {
                return obj[1].map((val) => {
                  if (val.type === 'ptSummary') {
                    return (!isPartnerSite ? (
                      <div className={style.summarySection}>
                        <Checkbox checked={ptSummary} onChange={this.handlePTSummaryChange} />
                        <span>{__('Program alert')}</span>
                        <p>{__('Notify me when each program changes')}</p>
                      </div>
                    ) : '');
                  }
                  return (
                    <div className={style.optionsWrapper}>
                      <div className={style.summarySection}>
                        <Checkbox checked={this.state[val?.type] || false} onChange={(e) => this.onChangeNotification(e, val)} />
                        <div>
                          <span>{val?.title}</span>
                          <p>{val?.content}</p>
                        </div>
                      </div>
                      <div className={style.childWrapper}>
                        {
                          val?.childrens?.map((item) => {
                            return <div className={style.summarySection}>
                              <Checkbox checked={this.state[item?.type] || false} onChange={(e) => this.onChangeNotification(e, item)} />
                              <div>
                                <span>{item?.title}</span>
                                <p>{item?.content}</p>
                              </div>
                            </div>
                          })
                        }
                      </div>
                    </div>
                  );
                });
              }
            })}
          </div>
        </div>
      </React.Fragment>

    );
  }
}

const mapStateToProps = ({ notificationSettings }) => ({
  preference: _get(notificationSettings, 'preference'),
  isFetchApiSuccess: _get(notificationSettings, 'isFetchApiSuccess'),
});

const mapDispatchToProps = dispatch => ({
  fetchUserPreference: baseUrl => dispatch(getUserPreferences(baseUrl)),
  updatePreference: (baseUrl, data, isApiFailed) => dispatch(updatePreferences(baseUrl, data, isApiFailed)),
  updateSummary: (baseUrl, data, isApiFailed) => dispatch(updateAppBasedSummary(baseUrl, data, isApiFailed)),
});

NotificationSettings.propTypes = {
  preference: PropTypes.object,
  fetchUserPreference: PropTypes.func.isRequired,
  updateSummary: PropTypes.func.isRequired,
  apiBaseUrl: PropTypes.string.isRequired,
  updatePreference: PropTypes.func.isRequired,
};

NotificationSettings.defaultProps = {
  preference: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettings);
