// Function to sort dates field
export const sortedDates = (dates, key) => {
  const sorttedDates = dates.sort((a, b) => (a[key] > b[key]) ? 1 : -1);
  return sorttedDates;
};

export const clearMigratedTokens = () => {
  const keys = [
    'access_token',
    'uname',
    'rememberMe',
    'email',
    'roleId',
    'idToken',
    'access_token_expire_date',
  ];
  keys.forEach(key => localStorage.removeItem(key));
};

export const capitalizeFirstLetter = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return '';
};

/**
 * @Purpose To construct user name with the properties from the user object
 * @param {object} user
 * @returns {String} (Firstname + Lastname) || (Firstname) || (email)
 */
export const formatUserName = (user) => {
  if (user) {
    const {
      firstName, lastName, email, name,
    } = user;
    if (firstName && lastName) {
      return `${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(lastName)}`;
    }
    if (!firstName && lastName) {
      return capitalizeFirstLetter(lastName);
    }
    if (firstName && !lastName) {
      return capitalizeFirstLetter(firstName);
    }
    if (!firstName && !lastName && name) {
      return capitalizeFirstLetter(user.name);
    }
    if (!firstName && !lastName && email) {
      return user.email;
    }
  }
  return '';
};

/**
   * @Purpose To construct random color
   * @returns {String} colorCode initials
   */
export const DEFAULT_COLORS = ['#648de6', '#bba8f6', '#2edcea', '#c06868', '#fc9a61', '#fddc4a', '#acd942', '#68bea8', '#eea4da', '#a1c2ff'];
const INDEXED_ALPHABETS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
export const getBackgroundColorCode = (name) => {
  if (!name) {
    return DEFAULT_COLORS[0];
  }
  const firstLetter = (name.trim() || '').charAt(0).toUpperCase();
  const index = INDEXED_ALPHABETS.indexOf(firstLetter) % 10;
  if (index) return DEFAULT_COLORS[index];
  return DEFAULT_COLORS[0];
};

export const getLandingPageUrl = () => {
  const { PARTNERS_SITE, LANDING_PAGE_URL } = process.env;
  return LANDING_PAGE_URL || (PARTNERS_SITE === 'true' ? '/home/mytasks' : '/app/home');
};

export const redirectToSAMLLogin = () => {
  const { API_GATEWAY_BASE_URL } = process.env;
  const searchParams = new URLSearchParams(window.location.search);
  const redirectUrl = searchParams.get('url') || getLandingPageUrl();
  window.location.href = `${API_GATEWAY_BASE_URL}/tapapi/duo_saml_auth/public/login?redirect=${redirectUrl}`;
}
